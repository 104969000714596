<template>
  <v-card>
    <v-card-title>テンプレート(編集)</v-card-title>
    <template>
      <v-spacer></v-spacer>
      <template v-if="apierror.status == 'error'">
        <div
          v-for="msg of apierror.messages"
          :key="msg"
        >
          <v-row class="ml-6 mb-3 ma-3">
            <span style="color: red">* {{ msg }} </span>
          </v-row>
        </div>
      </template>
    </template>
    <v-stepper v-model="page">
      <v-stepper-header>
        <v-stepper-step
          :complete="page > 1"
          step="1"
        >
          契約科目内容の選択と順番
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="page > 2"
          step="2"
        >
          契約科目内容の編集
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1" class="px-0">
          <template>
            <v-card-text>
              <v-row align="center">
                <v-col
                  cols="12"
                  md="2"
                >
                  <label>テンプレート名<span style="color: red">*</span></label>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="$v.Form.tmpl_name.$model"
                    outlined
                    dense
                    placeholder="テンプレート名"
                    :error-messages="nameErrors"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              <!-- <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <ContractItems
            :old-list="Form.details"
            :show-btn="false"
            @selectedList="getSelectedList"
          ></ContractItems>
        </div> -->
              </v-row>
              <v-row align="center">
                <v-col
                  cols="12"
                  md="2"
                >
                  <label>契約科目内容<span style="color: red">*</span></label>
                </v-col>
                <v-col
                  cols="12"
                  md="7"
                >
                  <v-sheet
                    width="100%"
                    min-height="300"
                    outlined
                    class="d-flex align-start flex-column"
                  >
                    <div class="d-flex justify-end px-4 py-1" style="width:100%;">
                      <ContractItems
                        class="ml-auto"
                        :old-list="Form.details"
                        :error-messages="templateDetailsErrors"
                        :show-btn="true"
                        @selectedList="getSelectedList"
                      ></ContractItems>
                    </div>
                    <v-divider style="width:100%;"></v-divider>
                    <v-list
                      style="width:100%;flex:1;"
                    >
                      <draggable
                        v-model="Form.details"
                        style="height:100%"
                        :group="groupSet"
                        animation="300"
                        @end="onEnd"
                      >
                        <v-list-item
                          v-for="(item, i) in Form.details"
                          :key="i"
                          style="height:14px; min-height:0;"
                          class="my-3"
                        >
                          <v-list-item-action class="my-0 mr-0 py-0">
                            <v-chip
                              style="font-size:14px;height:20px; min-height:0;width: 100%;"
                              class="d-flex justify-space-between"
                              @click.stop
                            >
                              <v-icon
                                size="16"
                                color="red"
                                class="mx-1"
                                @click="outFrList(item)"
                              >
                                {{ icons.mdiCloseCircleOutline }}
                              </v-icon>
                              <span
                                style="font-size:14px; color:black;width: 1000px; overflow: hidden; text-overflow:ellipsis;"
                                class="px-5"
                                :title="item.ci_name"
                              >
                                {{ item.ci_name }}
                              </span>
                              <!-- <v-text-field
                                v-model="item.ci_input"
                                style="font-size:14px; color:black;width: 800px; overflow: hidden; text-overflow:ellipsis;"
                                prefix=":"
                                dense
                                hide-details="auto"
                              ></v-text-field> -->
                            </v-chip>
                          </v-list-item-action>
                        </v-list-item>
                      </draggable>
                    </v-list>
                  </v-sheet>
                  <span v-if="detailsErrors" style="color:red;font-size:smaller;text-align:center">
                    契約科目を設定して下さい !
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions
              align="center"
              class="d-flex justify-center pb-0"
            >
              <v-btn
                color="primary"
                :loading="submitStatus"
                @click="toPage2(Form)"
              >
                Next
              </v-btn>
              <v-btn
                class="mx-2"
                outlined
                @click="cancelClick"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </template>
        </v-stepper-content>
      </v-stepper-items>
      <v-stepper-items>
        <v-stepper-content step="2" class="px-0">
          <v-data-table
            :headers="headers"
            :items="Form.details"
            item-key="ci_id"
            :error-messages="templateDetailsErrors"
            class="elevation-0 "
          >
            <template v-slot:[`item.ci_input`]="{ item }">
              <v-text-field
                v-model="item.ci_input"
                outlined
                dense
                hide-details="auto"
              >
              <!-- :error-messages="itemInputError(item)" -->
              </v-text-field>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <label>テンプレートメモ</label>
              </v-col>
              <v-col
                cols="12"
                md="7"
              >
                <v-textarea
                  v-model="$v.Form.tmpl_memo.$model"
                  counter="100"
                  rows="3"
                  outlined
                  dense
                  placeholder="テンプレートメモ"
                  :error-messages="memoErrors"
                  hide-details="auto"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions
            align="center"
            class="d-flex justify-center"
          >
            <v-btn
              outlined
              @click="page = 1"
            >
              Previous
            </v-btn>
            <v-btn
              color="primary"
              :loading="submitStatus"
              @click="submit(Form)"
            >
              Submit
            </v-btn>
            <v-btn
              outlined
              @click="cancelClick"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-card>
</template>

<script>
import {
  mapActions, mapMutations, mapState,
} from 'vuex'
import {
  mdiPencil, mdiDrag, mdiCloseCircleOutline,
} from '@mdi/js'
import {
  required, maxLength,
} from 'vuelidate/lib/validators'
import ContractItems from '@/components/ContractItems.vue'

export default {
  components: {
    ContractItems,
  },
  data: () => ({
    submitStatus: false,
    changeFlag: false,
    detailsErrors: false,
    page: 1,
    icons: {
      mdiPencil,
      mdiDrag,
      mdiCloseCircleOutline,
    },
    Form: {
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    groupSet: {
      name: 'Details',
      pull: false,
      put: false,
    },
    headers: [
      {
        text: 'No.',
        align: 'left',
        sortable: true,
        value: 'disp_order',
        width: '10%',
        fixed: true,
      },
      {
        text: '契約科目名',
        value: 'ci_name',
        sortable: true,
        align: 'left',
        width: '20%',
        fixed: true,
      },
      {
        text: '入力内容',
        value: 'ci_input',
        sortable: true,
        align: 'left',
        width: '70%',
        fixed: true,
      },
    ],
  }),

  validations: {
    Form: {
      tmpl_name: {
        required,
        maxLength: maxLength(100),
      },
      tmpl_memo: {
        maxLength: maxLength(100),
      },
      details: {
        required,
        ci_input: {
          maxLength: maxLength(100),
        },
      },
    },
  },
  computed: {
    ...mapState('templateStore', ['selectedTemplate']),

    nameErrors() {
      const errors = []
      if (!this.$v.Form.tmpl_name.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.tmpl_name.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.tmpl_name.maxLength && errors.push('100文字以内にして下さい。')

      return errors
    },
    memoErrors() {
      const errors = []
      if (!this.$v.Form.tmpl_memo.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.tmpl_memo.maxLength && errors.push('100文字以内にして下さい。')

      return errors
    },
    templateDetailsErrors() {
      const errors = []
      if (!this.$v.Form.details.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      // !this.$v.Form.details.required && errors.push('必須項目')

      return errors
    },

    // itemInputError() {
    //   return function (item) {
    //     console.log('item', item)
    //     const errors = []

    //     // if (item && item.disp_order) {
    //     //   const index = Number(item.disp_order) - 1
    //     //   console.log('index', index)
    //     //   if (!this.$v.Form.details.$each[index].ci_input.$dirty) return errors
    //     //   // eslint-disable-next-line no-unused-expressions
    //     //   !this.$v.Form.details.$each[index].ci_input.maxLength && errors.push('100文字以内にして下さい。')
    //     // }

    //     return errors
    //   }
    // },
  },
  watch: {
    Form: {
      handler() {
        this.changeFlag = true
      },
      deep: true,
    },
    'Form.details': {
      handler() {
        this.dispSort()
        if (this.Form.details.length === 0) {
          this.detailsErrors = true
        } else {
          this.detailsErrors = false
        }
      },
    },
  },
  created() {
    this.loadData()
  },
  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('templateStore', ['loadTemplate', 'editTemplate']),
    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadTemplate(this.$route.params.id)
        .then(() => {
          this.Form = this.$deepCopy(this.selectedTemplate)
          console.log('this.Form', this.Form)
        })
        .catch(error => {
          if (error.response) {
            this.apierror.status = 'error'
            this.apierror.messages = error.response.data.message
          }
        })
        .finally(() => {
          this.setOverlayStatus(false)
          console.log('Form.details', this.Form.details)
        })
    },
    getSelectedList(selectedList) {
      this.Form.details = selectedList.map((mal, i) => {
        const item = {
        }
        item.ci_id = mal.ci_id
        item.ci_name = mal.ci_name
        item.ci_input = mal.ci_input
        item.disp_order = i + 1

        return item
      })
    },
    outFrList(item) {
      const index = this.Form.details.findIndex(se => se.ci_id === item.ci_id)
      if (index >= 0) this.Form.details.splice(index, 1)
    },
    onEnd() {
      this.Form.details = this.Form.details.map((sd, i) => {
        const item = {
          ...sd,
        }
        item.disp_order = i + 1

        return item
      })
      console.log(this.Form.details)
    },
    dispSort() {
      const Arr = []
      this.Form.details.forEach((v, i) => {
        Arr[i] = v
        Arr[i].disp_order = i + 1
      })
    },
    jumpToListPage() {
      this.$router.push({
        path: '/template-list',
      })
    },

    click(item) {
      console.log(item)
    },

    toPage2(Form) {
      console.log('submitTo', Form)
      this.submitStatus = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.apierror.messages = []
        this.page = 2
        this.submitStatus = false
        window.scrollTo(0, 0)
      } else {
        console.log('error submit!!')
        window.scrollTo(0, 0)
        this.apierror.messages = []
        this.apierror.status = 'error'
        this.submitStatus = false
      }
    },

    submit(Form) {
      console.log('Form', Form)
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true

        this.editTemplate(Form)
          .then(() => {
            this.submitStatus = false
            this.jumpToListPage()
          })
          .catch(error => {
            this.submitStatus = false
            console.log(error.response.data)
            this.apierror.status = error.response.data.status
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message

            console.log('apierror', this.apierror)
          })
      } else {
        if (this.Form.details.length === 0) {
          this.detailsErrors = true
        } else {
          this.detailsErrors = false
        }
        console.log('error submit!!')
      }
    },
  },
}
</script>
<style scoped>
.drag-table-body-tr {
  border-bottom: 10px !important;
}
</style>
